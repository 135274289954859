import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { ColorLoadingIcon } from '@/components/icons/ColorLoadingIcon'

export default function Post() {
  const router = useRouter()
  const { from } = router.query

  useEffect(() => {
    window.location.href = 'https://rns.id/?utm_source=official&utm_medium=' + from + '&rc_by=' + from
  }, [])

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '400px' }}>
      <ColorLoadingIcon />
    </div>
  )
}
